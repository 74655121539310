@import 'variables';



//Main
.main {
    text-align: center;  
    width: 100%;
    font-size: 12px;
    background: #fbfbff;

    .plots-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 92vw;
        margin-left: 4vw;
        margin-right: 4vw;
        justify-items: stretch;
    }

    .plot {
        background: #ffffff;
        border: 1px dashed $tab-grey;
        width: 40vw;
        font-size: 12px;
        height: 600px;
        margin-top: 4vh;
        margin-bottom: 4vh;
        padding:20px;
        justify-self: center;
        position:relative;

        @media (min-width: 375px) and (max-width: 639px) {
            height: 250px;
        }

        @media (min-width: 640px) and (max-width: 767px) {
            height: 400px;
        }
        
        @media (min-width: 768px) and (max-width: 950px) {
            height: 400px;
        }   
    }

    .area-legend{
        right: 50px;
        z-index: 1;
        position: absolute;
        width: 600px;
    }
}
h4{
    margin-bottom: 0;
    margin-top: 0;
}

p{
    margin: 5px;
}

button {
    @extend %button;
}

.primary {
    color:white;
    background-color: $medium-pr-color;
    @extend %button;
}

.primary:hover {
    background-color: $strong-pr-color;
}



select{
    // border:1px solid $inactive-grey;
    margin:5px;
    padding:5px;
    cursor:pointer;
    @extend %regular-text;
}




  
  
  
  
 
  

  
  
  
  
